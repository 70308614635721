import {
    BC_SHAPE_LIST,
    cn_building,
    cn_opening,
    cn_opening_type,
    cn_slab,
    cn_slab_type,
    cn_space,
    cn_wall,
    WALL_STRUCTURE_LIST,
} from '@acenv/cnmap-editor';
import { CategorieOuvrage, CategorieOuvrageMapping, OuvrageAControler, OuvrageTmp } from '../model/categorie-ouvrage.model';
import { MongoUtils } from '../lib/utils/mongo.utils';
import { group } from '../lib/utils/array.utils';

/**
 * Actuellement dans cnmap, si on a 3 panneaux (ouvrants) sur une fenêtre on ne stocke que les 2 premières largeurs et on déduit la 3e largeur par la largeur totale
 * En plus, on stocke un pourcentage de largeur et non pas une largeur absolue.
 * @param opening_type
 */
function panelWidths(opening_type: cn_opening_type): number[] {
    const { width, panel_widths } = opening_type;
    const realWidths = panel_widths.map((it) => Math.round(it * width * 100) / 100);
    return [...realWidths, Math.round((width - realWidths.reduce((a, b) => a + b, 0)) * 100) / 100];
}

export function roundToCm(thickness: number) {
    return Math.round(thickness * 100) / 100;
}

export function getGenericOuvrages(building: cn_building, spacesNumerotation) {
    // on regroupe les slabs par storey et space (le space ID n'est pas forcément unique) et on ajoute l'info si il s'agit d'un slab sol ou plafond par rapport au space.
    const slabInfos = group(
        building.storeys.flatMap((storey, storeyIndex) =>
            storey.slabs.flatMap((slab: cn_slab) =>
                slab.spaces
                    .map((space: cn_space | null, index: number) =>
                        !!space
                            ? {
                                  slab,
                                  spaceId: space.ID,
                                  storeyId: index > 0 ? storey.ID : building.storeys[storeyIndex - 1]?.ID,
                                  floor: index > 0,
                                  ceiling: index === 0,
                                  slab_type: slab.slab_type as cn_slab_type,
                              }
                            : null
                    )
                    .filter((it) => !!it)
            )
        ),
        (it) => `${it.storeyId}-${it.spaceId}`
    );
    const ouvrages: OuvrageTmp[] = building.storeys
        ?.map((storey, storeyIndex) => [
            ...storey.scene?.spaces?.map((space, spaceIndex) => [
                ...(slabInfos[`${storey.ID}-${space.ID}`] ?? []).flatMap((it) => [
                    {
                        element: 'slab',
                        id: it.slab.ID,
                        spaceId: it.spaceId,
                        storeyId: it.storeyId,
                        floor: it.floor,
                        ceiling: it.ceiling,
                        thickness: it.slab_type.thickness,
                        categorie: it.slab_type.category,
                    },
                    ...it.slab_type.layers.map((layer, index) => ({
                        element: 'slab_layer',
                        id: it.slab.ID,
                        spaceId: it.spaceId,
                        storeyId: it.storeyId,
                        floor: it.floor,
                        ceiling: it.ceiling,
                        material: layer.code,
                        thickness: layer.thickness,
                        type: layer.type,
                        index,
                        floor_side: index < Math.floor(it.slab.slab_type.layers?.length / 2),
                        ceiling_side: index > Math.floor(it.slab.slab_type.layers?.length / 2),
                        categorie: it.slab_type.category,
                        partie: layer.code,
                    })),
                ]),
                ...(storey.scene.stairs
                    ?.filter((stair) => stair.space?.ID === space.ID)
                    ?.map((stair) => ({
                        id: stair.ID,
                        spaceId: space.ID,
                        storeyId: storey.ID,
                        element: 'stair',
                        categorie: stair.stairs_type,
                        palier: stair.vertices.length > 2,
                    })) ?? []),
                ...(storey.scene.beams
                    //TODO filtrer par espace dès que la version CNMAP le permet
                    ?.filter((beam) => space.outside)
                    ?.map((beam) => ({
                        id: beam?.ID,
                        spaceId: space.ID,
                        storeyId: storey.ID,
                        element: 'beam',
                        material: beam?.element_type.material,
                        thickness: beam?.element_type?.thickness,
                        width: beam?.element_type?.width,
                        shape: BC_SHAPE_LIST.find((it) => it.code === beam?.element_type.shape)?.label,
                    })) ?? []),
                ...(storey.scene.columns
                    //TODO filtrer par espace dès que la version CNMAP le permet
                    ?.filter((beam) => space.outside)
                    ?.map((column) => ({
                        id: column.ID,
                        spaceId: space.ID,
                        storeyId: storey.ID,
                        element: 'column',
                        material: column.element_type.material,
                        thickness: column?.element_type?.thickness,
                        width: column?.element_type?.width,
                        shape: BC_SHAPE_LIST.find((it) => it.code === column.element_type.shape)?.label,
                    })) ?? []),
                ...(storey.scene.pipes
                    //TODO filtrer par espace dès que la version CNMAP le permet
                    ?.filter((beam) => space.outside)
                    ?.map((pipe) => ({
                        id: pipe.ID,
                        spaceId: space.ID,
                        storeyId: storey.ID,
                        element: 'pipe',
                        fluid: pipe.element_type.fluid,
                        shape: pipe.element_type.shape,
                        material: pipe.element_type.material,
                        diameter: pipe.element_type.diameter,
                    })) ?? []),
                ...(storey?.scene?.walls
                    ?.filter((it) => it.spaces?.some((sp) => sp.ID === space.ID))
                    ?.map((wall: cn_wall) => [
                        {
                            id: wall.ID,
                            spaceId: space.ID,
                            storeyId: storey.ID,
                            element: 'wall',
                            categorie: wall.wall_type.category,
                            material: wall.wall_type?.layers?.find((it) =>
                                WALL_STRUCTURE_LIST.concat([{ code: 'gypsum', label: 'Plâtre' }]).some((w) => w.code === it.code)
                            )?.code,
                            exterior: wall.spaces.some((s) => s.outside),
                            free: wall.wall_type.category === 'inner' && !!wall.wall_type.free,
                            thickness: roundToCm(wall.wall_type.thickness),
                            wall_a: wall.openings.some((it: cn_opening) => space.main_door?.ID === it.ID),
                        },
                        ...(wall.wall_type.layers ?? []).map((layer, idx) => ({
                            id: `${wall.ID}-${idx}`,
                            spaceId: space.ID,
                            storeyId: storey.ID,
                            element: 'wall_layer',
                            categorie: wall.wall_type.category,
                            partie: layer.code,
                            material: layer.code,
                            exterior: wall.spaces.some((s) => s.outside),
                            outside: idx < wall.wall_type.layers?.length / 2,
                            inside: idx >= wall.wall_type.layers?.length / 2,
                            free: wall.wall_type.category === 'inner' && !!wall.wall_type.free,
                            // incohérence dans cn_map : le layer a une epaisseur, mais pas le type 'séparation fictive'
                            thickness: wall.wall_type.free ? 0 : roundToCm(layer.thickness),
                            layerIndex: idx,
                        })),
                    ])
                    .flat() ?? []),
                ...(storey?.scene?.walls
                    ?.filter((it) => it.spaces.some((sp) => sp.ID === space.ID))
                    ?.map((wall: cn_wall) =>
                        wall.openings?.map((opening: cn_opening) =>
                            [
                                {
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening',
                                    wallId: wall.ID,
                                    categorie: opening.opening_type.category,
                                    width: opening.opening_type.width,
                                    height: opening.opening_type.height,
                                    opening: opening.opening_type.opening,
                                    glazing: opening.opening_type.glazing,
                                    z: opening.opening_type.z,
                                    free: opening.opening_type.free,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    material: opening.opening_type.frame,
                                },
                                {
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening_frame',
                                    wallId: wall.ID,
                                    material: opening.opening_type.frame,
                                    categorie: opening.opening_type.category,
                                    opening: opening.opening_type.opening,
                                    glazing: opening.opening_type.glazing,
                                    width: opening.opening_type.width,
                                    height: opening.opening_type.height,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    free: opening.opening_type.free,
                                },
                                ...new Array(opening.opening_type.panels).fill(0).map((panel, panelIndex) => ({
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening_panel',
                                    wallId: wall.ID,
                                    categorie: opening.opening_type.category,
                                    width: panelWidths(opening.opening_type)[panelIndex],
                                    opening: opening.opening_type.opening,
                                    glazing: opening.opening_type.glazing,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    free: opening.opening_type.free,
                                    material: opening.opening_type.frame,
                                })),
                                {
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening_transom',
                                    wallId: wall.ID,
                                    categorie: opening.opening_type.category,
                                    transom: opening.opening_type.transom,
                                    material: opening.opening_type.transom_frame,
                                    height: opening.opening_type.transom_height,
                                    glazing: opening.opening_type.transom_glazing,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    free: opening.opening_type.free,
                                },
                                {
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening_sill',
                                    wallId: wall.ID,
                                    categorie: opening.opening_type.category,
                                    sill: opening.opening_type.sill,
                                    height: opening.opening_type.sill_height,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    free: opening.opening_type.free,
                                },
                                {
                                    id: opening.ID,
                                    spaceId: space.ID,
                                    storeyId: storey.ID,
                                    element: 'opening_closing',
                                    wallId: wall.ID,
                                    categorie: opening.opening_type.category,
                                    closing: opening.opening_type.closing,
                                    exterior: wall.spaces.some((s) => s.outside),
                                    free: opening.opening_type.free,
                                },
                            ].filter(Boolean)
                        )
                    )
                    ?.flat(2) ?? []),
            ]),
        ])
        ?.flat(2);
    return ouvrages;
}

function comparableWithDefaults<T extends object>(data: Partial<T>, defaultObject: Partial<T>): Partial<T> {
    return Object.keys(defaultObject).reduce((acc, key) => {
        const typedKey = key as keyof T;
        const dataValue = data[typedKey];
        const defaultValue = defaultObject[typedKey];

        // Cast dataValue and defaultValue to any to handle mixed type checks
        if (dataValue !== undefined && dataValue !== null && (dataValue as any) !== '') {
            acc[typedKey] = dataValue;
        } else if (defaultValue !== undefined && defaultValue !== null && (defaultValue as any) !== '') {
            acc[typedKey] = defaultValue;
        }

        return acc;
    }, {} as Partial<T>);
}

function areObjectsEqual<T extends object>(obj1: Partial<T>, obj2: Partial<T>): boolean {
    return Object.keys(obj1).every((key) => {
        const typedKey = key as keyof T;
        return obj1[typedKey] === obj2[typedKey];
    });
}

function applyCoefficient(ouvrageValue: string | boolean | number, coefficient: number | undefined) {
    // Check if ouvrageValue is a valid number and coefficient is defined
    if (typeof ouvrageValue === 'number' && !isNaN(ouvrageValue) && typeof coefficient === 'number') {
        return ouvrageValue * coefficient;
    }
    // If ouvrageValue is not a valid number, return the original value (or any fallback value you prefer)
    return ouvrageValue;
}

export function mapTypesAndParams(
    ouvragesTmp: OuvrageTmp[],
    categories: CategorieOuvrage[],
    mapping: CategorieOuvrageMapping[],
    defaultObject: Partial<OuvrageTmp>
): { ouvrage: OuvrageTmp; categorie: CategorieOuvrage; parametres: Record<string, number | string | boolean> }[] {
    const comparableMappings = mapping.map((it) => comparableWithDefaults(it, defaultObject));
    return ouvragesTmp
        .map((ouvrage) => {
            const ouvrageToCompare = comparableWithDefaults(ouvrage, defaultObject);

            const mappedItemIndex = comparableMappings.findIndex((it) => areObjectsEqual(it, ouvrageToCompare));

            if (mappedItemIndex < 0) {
                return null;
            }

            const mappedItem = mapping[mappedItemIndex];
            const categorie = categories.find((cat) => cat.code === mappedItem.codeCategorie);

            if (!categorie) {
                return null;
            }
            const parametres = Object.fromEntries(
                Object.entries(mappedItem.parametres ?? {})
                    .filter(([key, value]) => categorie.parametres.some((it) => it.code === value.code))
                    .map(([key, value]) => [
                        value.code,
                        ouvrage[key] ? value.valueMapping[ouvrage[key]] ?? applyCoefficient(ouvrage[key], value.coefficient) : undefined,
                    ])
            );
            if (ouvrage.wall_a) {
                parametres['AVEC_PORTE_PRINCIPALE'] = ouvrage.wall_a;
            }
            return {
                ouvrage,
                categorie,
                parametres,
            };
        })
        .filter(Boolean);
}

export function createOuvrage(ouvrage: OuvrageTmp, categorie: CategorieOuvrage, parametres: Record<string, string | number | boolean>) {
    const substrat = categorie.substrats.find((it) => it.codeCnmap === ouvrage.material);

    return {
        id: MongoUtils.generateObjectId(),
        nom: categorie.nomOuvrage ?? categorie.nom,
        objectId: ouvrage.id,
        codeCategorieOuvrage: categorie.code,
        partiesOuvrages: [],
        parametres,
        codeRevetement: '',
        nomRevetement: '',
        codeSubstrat: substrat?.code,
        nomSubstrat: substrat?.nom ?? ouvrage.material,
        valeurCouleur: null,
        nomCouleur: '',
        valeurCaracteristiqueCouleur: null,
    };
}

export interface CategoryNode {
    category: CategorieOuvrage;
    children: CategoryNode[];
    level: number;
}

function buildCategoryHierarchy(categories: CategorieOuvrage[]): Record<string, CategoryNode> {
    const categoryMap: Record<string, CategoryNode> = {};

    categories.forEach((category) => {
        categoryMap[category.code] = {
            category,
            children: [],
            level: -1,
        };
    });

    categories.forEach((category) => {
        const node = categoryMap[category.code];
        if (category.lienCodeParent) {
            const parent = categoryMap[category.lienCodeParent];
            if (parent) {
                parent.children.push(node);
                node.level = parent.level + 1;
            }
        } else {
            node.level = 0;
        }
    });

    function setLevelsRecursively(node: CategoryNode, currentLevel: number) {
        node.level = currentLevel;
        node.children.forEach((child) => {
            setLevelsRecursively(child, currentLevel + 1);
        });
    }

    for (const code in categoryMap) {
        const node = categoryMap[code];
        if (node.level === 0) {
            setLevelsRecursively(node, 0);
        }
    }

    return categoryMap;
}

function insertMissingParents(
    ouvrages: OuvrageAControler[],
    categoryHierarchy: Record<string, CategoryNode>,
    categoryLevelThreshold: number,
    ouvrageMap: Record<string, OuvrageAControler>
) {
    const missingParentOuvrages: OuvrageAControler[] = [];

    ouvrages.forEach((ouvrage) => {
        const categoryNode = categoryHierarchy[ouvrage.codeCategorieOuvrage];

        if (categoryNode && categoryNode.category.lienCodeParent) {
            const parentCategoryNode = categoryHierarchy[categoryNode.category.lienCodeParent];

            const potentialParentOuvrage = Object.values(ouvrageMap).find(
                (o) =>
                    o.codeCategorieOuvrage === parentCategoryNode.category.code &&
                    (categoryNode.level <= categoryLevelThreshold || ouvrage.objectId.includes(o.objectId))
            );

            if (!potentialParentOuvrage) {
                // Create and insert the missing parent node
                const missingParentOuvrage: OuvrageAControler = {
                    id: MongoUtils.generateObjectId(),
                    objectId: parentCategoryNode.level >= 1 ? ouvrage.objectId : null,
                    codeCategorieOuvrage: parentCategoryNode.category.code,
                    partiesOuvrages: [],
                    nom: parentCategoryNode.category.nomOuvrage,
                    parametres: {},
                    codeSubstrat: null,
                    codeRevetement: null,
                    nomSubstrat: null,
                    nomRevetement: null,
                    nomCouleur: null,
                    valeurCouleur: null,
                    valeurCaracteristiqueCouleur: null,
                };

                missingParentOuvrages.push(missingParentOuvrage);
                ouvrageMap[missingParentOuvrage.id] = missingParentOuvrage;
                missingParentOuvrage.partiesOuvrages.push(ouvrage);
                insertMissingParents([missingParentOuvrage], categoryHierarchy, categoryLevelThreshold, ouvrageMap);
            } else {
                potentialParentOuvrage.partiesOuvrages.push(ouvrage);
            }
        }
    });
}

export function buildOuvrageTree(ouvrages: OuvrageAControler[], categories: CategorieOuvrage[], categoryLevelThreshold: number): OuvrageAControler[] {
    const categoryHierarchy = buildCategoryHierarchy(categories);

    const ouvrageMap: Record<string, OuvrageAControler> = {};

    ouvrages.forEach((ouvrage) => {
        ouvrage.partiesOuvrages = [];
        ouvrageMap[ouvrage.objectId] = ouvrage;
    });

    const rootOuvrages: OuvrageAControler[] = [];

    ouvrages.forEach((ouvrage) => {
        const categoryNode = categoryHierarchy[ouvrage.codeCategorieOuvrage];

        if (categoryNode && categoryNode.category.lienCodeParent) {
            const parentCategoryNode = categoryHierarchy[categoryNode.category.lienCodeParent];

            const potentialParentOuvrage = ouvrages.find(
                (o) =>
                    o.codeCategorieOuvrage === parentCategoryNode.category.code &&
                    (categoryNode.level <= categoryLevelThreshold || o.objectId === ouvrage.objectId)
            );

            if (potentialParentOuvrage) {
                potentialParentOuvrage.partiesOuvrages.push(ouvrage);
            } else {
                rootOuvrages.push(ouvrage);
            }
        } else {
            rootOuvrages.push(ouvrage);
        }
    });

    // After building the tree, insert missing parent nodes
    insertMissingParents(rootOuvrages, categoryHierarchy, categoryLevelThreshold, ouvrageMap);

    return Object.values(ouvrageMap).filter((it) => categoryHierarchy[it.codeCategorieOuvrage].level === 0);
}

export function mergeOuvrageTrees(masterTree: OuvrageAControler[], currentTree: OuvrageAControler[], level: number = 0): OuvrageAControler[] {
    const mergeNode = (masterNode: OuvrageAControler, currentNode: OuvrageAControler | undefined, level: number) => {
        if (currentNode) {
            // Merge child nodes recursively if they have objectId or at the first level
            const currentChildMap = new Map(currentNode.partiesOuvrages.map((child) => [`${child.objectId}-${child.codeCategorieOuvrage}`, child]));

            masterNode.partiesOuvrages = masterNode.partiesOuvrages
                .map((masterChild) => {
                    const correspondingCurrentChild = currentChildMap.get(`${masterChild.objectId}-${masterChild.codeCategorieOuvrage}`);
                    if (level === 0 || masterChild.objectId) {
                        return mergeNode(masterChild, correspondingCurrentChild, level + 1);
                    }
                    return masterChild; // Preserve nodes without objectId in subsequent levels
                })
                .filter((child) => child !== null); // Remove children that don't exist in current version
            // Update masterNode properties from currentNode, except for id
            Object.keys(masterNode)
                .filter((key) => !['id', 'parametres'].includes(key))
                .forEach((key) => ((masterNode as any)[key] = (currentNode as any)[key]));
            // Specifically handle the `parametres` property to update only existing keys
            if (currentNode.parametres) {
                masterNode.parametres = masterNode.parametres || {};
                Object.keys(currentNode.parametres).forEach((paramKey) => {
                    masterNode.parametres[paramKey] = currentNode.parametres[paramKey];
                });
            }
            // Add new nodes that exist in the current tree but not in the master tree
            const masterChildMap = new Map(masterNode.partiesOuvrages.map((child) => [`${child.objectId}-${child.codeCategorieOuvrage}`, child]));
            currentNode.partiesOuvrages.forEach((currentChild) => {
                if (!masterChildMap.has(`${currentChild.objectId}-${currentChild.codeCategorieOuvrage}`)) {
                    masterNode.partiesOuvrages.push(currentChild);
                }
            });
        } else if (level === 0 || masterNode.objectId) {
            // If the node does not exist in the current tree and has an objectId, remove it completely by returning null
            return null;
        }

        return masterNode;
    };

    const currentMap = new Map(currentTree.map((node) => [`${node.objectId}-${node.codeCategorieOuvrage}`, node]));

    // Merge the root nodes
    const mergedTree = masterTree
        .map((masterNode) => {
            const correspondingCurrentNode = currentMap.get(`${masterNode.objectId}-${masterNode.codeCategorieOuvrage}`);
            return mergeNode(masterNode, correspondingCurrentNode, 0);
        })
        .filter((node) => node !== null); // Remove nodes that don't exist in the current version

    // Add any root nodes that are new in the current tree
    const masterMap = new Map(mergedTree.map((node) => [`${node.objectId}-${node.codeCategorieOuvrage}`, node]));
    currentTree.forEach((currentNode) => {
        if (!masterMap.has(`${currentNode.objectId}-${currentNode.codeCategorieOuvrage}`)) {
            mergedTree.push(currentNode);
        }
    });

    return mergedTree;
}
