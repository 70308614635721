<div class="d-flex flex-column h-100">
    <div class="w-100 d-flex flex-grow-0 py-2 align-items-center border-bottom">
        <div class="col-6 d-flex align-items-center justify-content-between">
            <span class="text-small mr-2">Visitée</span>
            <div>
                <button
                    mat-icon-button
                    matTooltip="Visitée"
                    class="small-icon-button"
                    [disabled]="readonlyMode"
                    (click)="handleClickVolumeVisite(VOLUME_VISITE.OK)"
                >
                    <mat-icon
                        fontSet="material-icons-two-tone"
                        class="success"
                        [ngClass]="{
                            'icon-disabled':
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.OK
                        }"
                        >lock_open
                    </mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="Partiellement visitée"
                    class="small-icon-button"
                    [disabled]="readonlyMode"
                    (click)="handleClickVolumeVisite(VOLUME_VISITE.WARNING)"
                >
                    <mat-icon
                        fontSet="material-icons-two-tone"
                        class="justify"
                        [ngClass]="{
                            'icon-disabled':
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.WARNING
                        }"
                        >lock
                    </mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="Non visitée"
                    class="small-icon-button"
                    [disabled]="readonlyMode"
                    (click)="handleClickVolumeVisite(VOLUME_VISITE.KO)"
                >
                    <mat-icon
                        fontSet="material-icons-two-tone"
                        class="error"
                        [ngClass]="{
                            'icon-disabled':
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.KO
                        }"
                        >lock
                    </mat-icon>
                </button>
            </div>
            <div class="ml-2 flex-grow-1">
                <div *ngIf="!currentVolume.valeursParametres[PARAM_VOLUME_VISITE]" class="to-justify py-0">
                    <span class="text-small">Il manque un état de visite</span>
                </div>
                <div
                    *ngIf="
                        currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                        (currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING ||
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO) &&
                        !currentVolume.justifie
                    "
                    class="to-justify py-0"
                >
                    <span class="text-small">Il manque une justification de non visite</span>
                    <button mat-icon-button color="justify" class="ml-1" [disabled]="readonlyMode" (click)="addJustificationNonVisite()">
                        <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-start align-items-center">
            <span>{{ currentVolume.nom }}</span>
            <button
                *ngIf="!currentVolume.volumeCache"
                mat-icon-button
                matTooltip="Editer le volume"
                [disabled]="readonlyMode"
                (click)="handleEditVolume()"
            >
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-center">
            <button
                *ngIf="!currentVolume.volumeCache && !currentVolume.spaceId"
                mat-icon-button
                matTooltip="Supprimer le volume"
                class="warn"
                [disabled]="readonlyMode"
                (click)="handleSupprimerVolume()"
            >
                <mat-icon>delete</mat-icon>
            </button>
            <button
                *ngIf="!currentVolume.volumeCache"
                mat-icon-button
                matTooltip="Dupliquer le volume"
                [disabled]="readonlyMode"
                (click)="handleDupliquerVolume()"
            >
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Ajouter un commentaire" [disabled]="readonlyMode" (click)="handleCommenterVolume()">
                <mat-icon
                    [matBadgeHidden]="currentVolume.commentairesId.length == 0"
                    matBadge="{{ currentVolume.commentairesId.length }}"
                    matBadgeColor="accent"
                    >comment</mat-icon
                >
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="!currentVolume.volumeCache">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item matTooltip="Fusionner le volume" [disabled]="readonlyMode" (click)="handleMergeVolume()">
                    <mat-icon>swap_horiz</mat-icon>
                    <span>Fusionner</span>
                </button>

                <button
                    mat-menu-item
                    matTooltip="Déplacer le volume"
                    [disabled]="readonlyMode || this.currentVolume.spaceId != null"
                    (click)="handleDeplacerVolume()"
                >
                    <mat-icon>move_down</mat-icon>
                    <span>Déplacer</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="d-flex w-100 flex-grow-1">
        <div class="d-flex w-100 mh-100 h-100">
            <div class="col-4 pt-2 mh-100 h-100">
                <div class="d-flex align-items-center">
                    <button
                        class="border rounded"
                        mat-icon-button
                        matTooltip="Ajouter un ouvrage"
                        (click)="handleAddOuvrage()"
                        data-cy="ajouter-equipement-button"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                    <span class="p-2">Ouvrages</span>
                </div>
                <div class="mt-3" *ngFor="let categorieParente of currentOuvragesList">
                    <mat-card class="d-flex row justify-content-between align-items-center mx-1 my-2 p-0 rounded">
                        <div class="flex-grow-1 d-flex align-items-center">
                            <div class="px-1 m-0 flex-grow-1 text-small text-nowrap">
                                {{ categorieParente.nom }}
                            </div>

                            <button
                                class="flex-grow-0"
                                mat-icon-button
                                matTooltip="Paramétrer l'ouvrage"
                                (click)="handleParametrerCategorieOuvrageByKey(categorieParente.key)"
                            >
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div>
                        <div class="d-flex w-100" *ngFor="let categorie of categorieParente.categories">
                            <div class="pl-2 m-0 text-small text-nowrap">({{ categorie.count }}) {{ categorie.nom }}</div>
                            <div class="flex-grow-1 pl-1 py-0 m-0 flex-grow-1 text-small text-muted overflow-hidden">
                                {{ categorie.substrats | joinList }}
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="col-4 pt-2 mh-100 h-100 border-left border-right">
                <div class="d-flex align-items-center">
                    <button
                        class="border rounded"
                        mat-icon-button
                        matTooltip="Ajouter un équipement"
                        (click)="handleAddEquipement()"
                        data-cy="ajouter-equipement-button"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                    <span class="p-2">Equipements</span>
                    <app-description-bien-filter-type-prestations
                        class="overflow-auto"
                        [selectedRefPrestations]="selectedRefPrestations"
                        (selectedRefPrestationsChange)="handleSelectedRefPrestations($event)"
                    >
                    </app-description-bien-filter-type-prestations>
                </div>
                <div class="mt-3">
                    <mat-card
                        *ngFor="let element of listElementsToDisplay"
                        class="d-flex row no-gutters justify-content-between align-items-center px-1 my-2 py-0 rounded"
                    >
                        <div class="d-flex align-items-center py-2">
                            <span class="mx-1">
                                <ng-container *ngFor="let typePresta of element | typePrestationForEquipement: allCategoriesEquipements">
                                    <ng-container *ngIf="refPrestations[typePresta] | pictoPrestation | async as fileContent">
                                        <img
                                            *ngIf="fileContent"
                                            [src]="fileContent"
                                            matTooltip="{{ typePresta | typePrestation }}"
                                            alt=""
                                            class="rounded rounded-circle diag-equipement"
                                        />
                                    </ng-container>
                                </ng-container>
                            </span>
                            <span class="text-small">{{ element.name | uppercase }}</span>
                        </div>
                        <button
                            *ngIf="!element.objectId"
                            mat-icon-button
                            matTooltip="Supprimer l'équipement"
                            class="warn"
                            [disabled]="readonlyMode"
                            (click)="handleSupprimerEquipement(element)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card>
                </div>
            </div>
            <div class="col-4 pt-2 mh-100 h-100" *ngIf="!currentVolume.volumeCache">
                <div class="d-flex p-2 align-items-center">Paramètres</div>
                <div class="mt-3">
                    <mat-card class="d-flex row justify-content-between align-items-center mx-1 my-2 p-0 rounded">
                        <span class="w-75 pl-2 text-small">{{ 'Pièce humide' | uppercase }}</span>
                        <app-state-input
                            class="w-25 d-flex justify-content-center"
                            [disabled]="readonlyMode"
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="tooltipParametresVolume"
                            [(choiceValue)]="currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_HUMIDE]"
                        ></app-state-input>
                    </mat-card>
                    <mat-card class="d-flex row justify-content-between align-items-center mx-1 my-2 p-0 rounded">
                        <span class="w-75 pl-2 text-small">{{ 'Pièce non réputée Carrez' | uppercase }}</span>
                        <app-state-input
                            class="w-25 d-flex justify-content-center"
                            [disabled]="readonlyMode"
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="tooltipParametresVolume"
                            [(choiceValue)]="currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ]"
                        ></app-state-input>
                    </mat-card>
                    <mat-card class="d-flex row justify-content-between align-items-center mx-1 my-2 p-0 rounded">
                        <span class="w-75 pl-2 text-small">{{ 'Pièce non réputée habitable' | uppercase }}</span>
                        <app-state-input
                            class="w-25 d-flex justify-content-center"
                            [disabled]="readonlyMode"
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="tooltipParametresVolume"
                            [(choiceValue)]="currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE]"
                        ></app-state-input>
                    </mat-card>
                    <mat-card class="d-flex row justify-content-between align-items-center mx-1 my-2 p-0 rounded">
                        <span class="w-75 pl-2 text-small">{{ 'Pièce non réputée utile' | uppercase }}</span>
                        <app-state-input
                            class="w-25 d-flex justify-content-center"
                            [disabled]="readonlyMode"
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="tooltipParametresVolume"
                            [(choiceValue)]="currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE]"
                        ></app-state-input>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
